@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

:root {
  height: 100vh;
  width: 100vw;
  background: #161616;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #24A8DF;
  border-radius: 0.5rem;
  width: 8px;
}